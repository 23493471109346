import React from 'react'
import Wave from './Wave'
import { motion } from 'framer-motion'
import { TitleAnim, PageAnimation } from '../Animation'

const HomeTitle = () => {
    return (
        <motion.div variants={PageAnimation} initial="hidden" animate="show" exit="exit" className='home_title'>
            <Wave />
            <div className="container hide name">
                <motion.h1 variants={TitleAnim}>Commonplace</motion.h1>
                <span>R</span>
            </div>
            <div className="container description " style={{display:"unset"}} >
                <p >Established in 2019 by Marwah Alomari, Commonplace Studio is driven by a passion for art and design. Our goal is simple yet powerful: to create innovative branding solutions that evoke emotion and make a lasting impact. With a commitment to pushing boundaries and embracing creativity, we help clients bring their brand visions to life. Welcome to Commonplace, where art and design converge to shape inspiring brand experiences.</p>
            </div>
            <div className="container componentTitle ">
                <div className="timeLine">1 / 6</div>
                <div className="timeLine"><span>E</span><span>S</span><span>T</span> <p> 2019</p></div>
            </div>
        </motion.div>
    )
}

export default HomeTitle