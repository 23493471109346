import "./styles/app.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/projects/Home";
import Figoo from "./Components/projects/Figoo";
import Ray from "./Components/projects/Ray";
import Shukran from "./Components/projects/Shukran";
import Ambiance from "./Components/projects/Ambiance";
import Soil from "./Components/projects/Soil";
import Archi from "./Components/projects/Archi";
import Cone from "./Components/projects/Cone";
import Goya from "./Components/projects/Goya";
import { PassCheck } from "./Components/PassCheck";
import { Jewar } from "./Components/projects/Jewar";
import { Fleurology } from "./Components/projects/Fleurology";
import { Adzone } from "./Components/projects/Adzone";
import { Goyasocial } from "./Components/projects/Goyasocial";
import { Tony } from "./Components/projects/Tony";
import { Astronaut } from "./Components/projects/Astronaut";
import { Coffea } from "./Components/projects/Coffea";
import { Soillaunch } from "./Components/projects/Soillaunch";
import Take from "./Components/projects/Take";
import { Trbbranding } from "./Components/projects/Trbbranding";
import { Trbscial } from "./Components/projects/Trbscial";
import { PasscheckTony } from "./Components/PasscheckTony";
import { Passcheckgoya } from "./Components/Passcheckgoya";
import { Passchectrb } from "./Components/Passchecktrb";
import { NadiyaIthara } from "./Components/projects/NadiyaIthara";
import { Oomiya } from "./Components/projects/Omiyaa";
import { PlentySLD } from "./Components/projects/PlentySlds";
import { SoilVFX } from "./Components/projects/SoilVFX";
import { Chemistry } from "./Components/projects/Chemistry";
import { WebBrew } from "./Components/projects/Webbrew";
import ProjectSlug from "./Components/projects/ProjectSlug";

function App() {
  return (
    <>
      <Routes>
        <Route path="/passcheck" element={<PassCheck />} />
        <Route path="/figo" element={<Figoo />} />
        <Route path="/ray" element={<Ray />} />
        <Route path="/shukran" element={<Shukran />} />
        <Route path="/ambiance" element={<Ambiance />} />
        <Route path="/soil" element={<Soil />} />
        <Route path="/archi" element={<Archi />} />
        <Route path="/conezone" element={<Cone />} />
        <Route path="/goya" element={<Goya />} />
        <Route path="/jewar" element={<Jewar />} />
        <Route path="/fleurology" element={<Fleurology />} />
        <Route path="/adzone" element={<Adzone />} />
        <Route path="/goyasocial" element={<Goyasocial />} />
        <Route path="/tony" element={<Tony />} />
        <Route path="/astronaut" element={<Astronaut />} />
        <Route path="/coffea" element={<Coffea />} />
        <Route path="/soillaunch" element={<Soillaunch />} />
        <Route path="/take" element={<Take />} />
        <Route path="/trbbranding" element={<Trbscial />} />
        <Route path="/trbsocial" element={<Trbbranding />} />
        <Route path="/passchecktony" element={<PasscheckTony />} />
        <Route path="/passcheckgoya" element={<Passcheckgoya />} />
        <Route path="/passchecktrb" element={<Passchectrb />} />
        <Route path="/nadiyaithra" element={<NadiyaIthara />} />
        <Route path="/oomiya" element={<Oomiya />} />
        <Route path="/plentysld" element={<PlentySLD />} />
        <Route path="/soilvfx" element={<SoilVFX />} />
        <Route path="/chemistry" element={<Chemistry />} />
        <Route path="/webrew" element={<WebBrew />} />
        <Route path="/projects/:id" element={<ProjectSlug />} />
        <Route path="/" element={<Home />} exact />
      </Routes>
    </>
  );
}

export default App;
