import React, { useEffect, useState } from "react";
import v1 from "../../assets/projects/plentysld/movie1.mov";
import v2 from "../../assets/projects/plentysld/movie2.MP4";
import Nav from "./Nav";
import ScrollTop from "../ScrollTop";
import Contact from "../Contact";
import Recent from "./Recent";
import Loader from "./Loader";

export const PlentySLD = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Nav />
          <div className="figo">
            <div className="container">
              <div className="backImage plentyImgBef">
                <div className="overlay"></div>
                <h1>Plenty SLD's</h1>
              </div>
            </div>
            <h1>Story</h1>
            <div className="para">
              <p>
                Embarking on a mission to make Riyadh greener, Plenty Salad
                creatively plants elements of Diriyah in the city, harmoniously
                merging contemporary art with the richness of tradition.
              </p>
            </div>
            <div className="imge">
              <div className="f archiStyle">
                <video
                  src={v1 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
              <div className="f archiStyle">
                <video
                  src={v2 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
            </div>
            <h1>Recent Projects</h1>
            <Recent />
          </div>
          <Contact />
          <ScrollTop />
        </>
      )}
    </>
  );
};
