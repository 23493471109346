import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// month one
import mv1 from "../../assets/projects/goyasocial/month1/video/1.mp4"
import mv3 from "../../assets/projects/goyasocial/month1/video/3.mp4"
import mi2 from "../../assets/projects/goyasocial/month1/image/2.jpg"
import mi3 from "../../assets/projects/goyasocial/month1/image/3.png"
import mi6 from "../../assets/projects/goyasocial/month1/image/6.jpg"
// month two
import mtv1 from "../../assets/projects/goyasocial/month2/video/1.mp4"
import mtv2 from "../../assets/projects/goyasocial/month2/video/2.mp4"
import mtv3 from "../../assets/projects/goyasocial/month2/video/3.mp4"
import mti1 from "../../assets/projects/goyasocial/month2/image/1.png"
import mti3 from "../../assets/projects/goyasocial/month2/image/3.jpg"
import mti4 from "../../assets/projects/goyasocial/month2/image/4.jpg"
// month three
import mttv1 from "../../assets/projects/goyasocial/month3/video/1.mp4"
import mttv2 from "../../assets/projects/goyasocial/month3/video/2.mp4"
import mttv3 from "../../assets/projects/goyasocial/month3/video/3.mp4"
import mttv4 from "../../assets/projects/goyasocial/month3/video/4.mp4"
import mtiv2 from "../../assets/projects/goyasocial/month3/image/2.jpg"
import mtiv3 from "../../assets/projects/goyasocial/month3/image/3.jpg"
import mtiv4 from "../../assets/projects/goyasocial/month3/image/4.jpg"
// lazt loading
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Goyasocial = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage goyasocialImgBef">
                            <div className="overlay"></div>
                            <h1>Goya Social Media</h1>
                        </div>
                    </div>
                    <h1>Goya Story</h1>
                    <div className="para">
                        <p>In the bustling city of Foodville, the renowned burger shop "Goya" launched their captivating campaign, "Hungry Everywhere." Creative billboards strategically placed throughout the city enticed
                            passersby with cravings for Goya's mouthwatering burgers. As the campaign expanded, Goya became a symbol of satisfaction and culinary delight.</p> <br />
                        <p>With the "Hungry at the Road" campaign, Goya's captivating billboards ignited cravings and turned the city streets into pathways of temptation. The "Hungry at Summer" campaign brought vibrant
                            food trucks to local beaches and parks, attracting sun-soaked visitors with the enticing aroma of Goya's delicious burgers. Goya further delighted the corporate world with the "Hungry at the Oﬃce"
                            campaign, delivering scrumptious burger lunches to fuel hardworking professionals. The "Hungry on the Move" campaign catered to travelers at highway rest stops, offering mouthwatering burgers
                            for weary adventurers.</p> <br />
                        <p>Throughout the "Hungry Everywhere" campaign, Goya's story resonated with customers, emphasizing that their mouthwatering burgers could satisfy cravings wherever hunger struck. Goya became
                            a symbol of culinary delight, spreading satisfaction and joy throughout Foodville.</p>
                    </div>
                    <h1>Social Media First Month</h1>
                    <div style={{ paddingTop: 0 }} className="logos">
                        <div className="imageLogo ambImgLogo rellamb">
                            <video src={mv1 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid goyaSocialVid">
                            <video src={mv3 + "#t=1"} controls="controls" muted alt="" ></video>
                            <img effect="blur" src={mi6} alt="" />
                            <img effect="blur" src={mi2} alt="" />
                            <img effect="blur" src={mi3} alt="" />
                        </div>
                    </div>
                    <h1>Social Media Second Month</h1>
                    <div className="imge vid" style={{ paddingTop: '0px' }} >
                        <div className="f">
                            <video src={mtv1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={mtv2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={mtv3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>

                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={mti3} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={mti4} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={mti1} alt="" />
                            </div>
                        </div>
                    </div>
                    <h1>Social Media Third Month</h1>
                    <div className="imge vid" style={{ paddingTop: '0px' }} >
                        <div className="f">
                            <video src={mttv1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={mttv2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={mttv3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={mttv4 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={mtiv2} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={mtiv3} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={mtiv4} alt="" />
                            </div>
                        </div>
                    </div>

                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}

        </>
    )
}
