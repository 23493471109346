import React,  { useEffect, useState } from 'react'
import f1 from "../../assets/projects/ray/merch/1.jpg"
import f2 from "../../assets/projects/ray/merch/2.jpg"
import f3 from "../../assets/projects/ray/merch/3.jpg"
import f4 from "../../assets/projects/ray/merch/4.jpg"
import f5 from "../../assets/projects/ray/merch/5.jpg"
import f6 from "../../assets/projects/ray/merch/6.jpg"
import r1 from "../../assets/projects/ray/paper/1.jpg"
import r2 from "../../assets/projects/ray/paper/2.jpg"
import r3 from "../../assets/projects/ray/paper/3.jpg"
import r4 from "../../assets/projects/ray/paper/4.jpg"
import inv2 from "../../assets/projects/ray/paper/invitation2.jpg"
import v1 from "../../assets/projects/ray/video/v1.mp4"
import v2 from "../../assets/projects/ray/video/v2.mp4"
import v4 from "../../assets/projects/ray/video/v4.mp4"
import v5 from "../../assets/projects/ray/video/v5.mp4"
import v6 from "../../assets/projects/ray/video/v6.mp4"
import ScrollTop from '../ScrollTop'
import { Link } from 'react-router-dom'
import coverF from "../../assets/projects/figo/logo.jpg"
import coverA from "../../assets/projects/amb/brand/9.jpg"
import coverS from "../../assets/projects/shukran/IMG_7587.JPG"
import coverSoil from "../../assets/projects/soil/02-min.jpeg"
import Nav from './Nav'
import Contact from '../Contact'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from './Loader'


const Ray = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
        {loading ? <Loader /> :<>
            <Nav />
            <div className='figo'>
                <div className="container">
                    <div className="backImage rayImgBef">
                        <div className="overlay"></div>
                        <h1>RAY</h1>
                    </div>
                </div>
                <div className="intro">
                    <p>It’s RAY!!! The owner of the newest Pizza Parlor in town🍕!
                        He sees the traditional stuff and wants to make a bigger statement! He is now out with his paint and markers, vandalizing the tallest building in Al Khobar ! calling out for YOUR attention.
                    </p>
                </div>
                <div className="imge vid">
                    <div className="f autoHeight">
                        <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                    </div>
                    <div className="f autoHeight">
                        <video src={v5 + "#t=1"} controls="controls" muted alt="" />
                    </div>
                    <div className="f autoHeight">
                        <video src={v6 + "#t=1"} controls="controls" muted alt="" />
                    </div>
                </div>
                <h1>Ray’s merchs</h1>
                <div className="imge">
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f1} alt="" />
                    </div>
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f2} alt="" />
                    </div>
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f3} alt="" />
                    </div>
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f4} alt="" />
                    </div>
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f5} alt="" />
                    </div>
                    <div className="f amb ambre">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={f6} alt="" />
                    </div>
                </div>
                <h1>Teaser</h1>
                <div className="imge imgeTeaser">
                    <div className="f amb inter resDiv">
                        <video style={{ padding: "0.5rem" }} className="res" src={v1 + "#t=1"} controls="controls" muted alt="" />
                    </div>
                    <div className="f amb inter resDiv">
                        <video style={{ padding: "0.5rem" }} className="res" src={v2 + "#t=1"} controls="controls" muted alt="" />
                    </div>
                    <div className="f amb inter resDiv">
                        <video style={{ padding: "0.5rem" }} className="res" src={v4 + "#t=1"} controls="controls" muted alt="" />
                    </div>
                </div>
                <h1>Ray’s invitation</h1>
                <div style={{ paddingTop: 0 }} className="logos">
                    <div className="imageLogo ambImgLogo">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={inv2} alt="" />
                    </div>
                    <div className="logoVid ambLogoVid">
                        <img src={r1} alt="" />
                        <img src={r2} alt="" />
                        <img src={r3} alt="" />
                        <img src={r4} alt="" />
                    </div>
                </div>
                <div className="services">
                    <h1>SERVICES:</h1>
                    <ul>
                        <li>Branding</li>
                        <li>packaging</li>
                        <li>BRAND PERSONALITY</li>
                        <li>PHOTOGRAPHY DIRECTION</li>
                        <li>teasers</li>
                        <li>INVITATION</li>
                    </ul>
                </div>
                <h1>Recent Projects</h1>
                <div className="imge">
                    <Link to="/ambiance">
                        <div className=" recproject">
                            <h1>Ambiance</h1>
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={coverA} alt="" />
                        </div>
                    </Link>
                    <Link to="/figo">
                        <div className=" recproject">
                            <h1>Figo</h1>
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={coverF} alt="" />
                        </div>
                    </Link>
                    <Link to="/shukran">
                        <div className=" recproject">
                            <h1>Shukran</h1>
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={coverS} alt="" />
                        </div>
                    </Link>
                    <Link to="/soil">
                        <div className=" recproject">
                            <h1>Soil</h1>
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={coverSoil} alt="" />
                        </div>
                    </Link>
                </div>
            </div>
            <Contact />
            <ScrollTop /></>}
        </>
    )
}

export default Ray