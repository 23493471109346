import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// media
import v1 from "../../assets/projects/soilCamp/v3.mp4"
import i1 from "../../assets/projects/soilCamp/1.png"
import i4 from "../../assets/projects/soilCamp/4.png"
import i6 from "../../assets/projects/soilCamp/6.png"
import i7 from "../../assets/projects/soil/invi.mp4"
import i8 from "../../assets/projects/soil/invi2.mp4"


export const Soillaunch = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])
  return (
    <>
      {loading ? <Loader /> : <> <Nav />
        <div className='figo'>
          <div className="container">
            <div className="backImage soilcampImgBef">
              <div className="overlay"></div>
              <h1>SOIL LAUNCH CAMPAIGN</h1>
            </div>
          </div>
          <h1>Story</h1>
          <div className="intro">
            <p>Building a better community brick by brick, over a warm cup of coffee.
            </p>
            <p>Imagine attempting to enter a coffee shop that has the impression of being well-made and has a sense of history and craftsmanship in every
              aspect. The space is designed to evoke a sense of permanence, with a mix of brick walls, aged wooden beams, and carved marble
              accents.</p>
            <p>As you look around, you notice the attention to detail in every element, from the carefully laid stones on the floor to the intricately carved
              marble counter. The aged stones and carved marble give the space a sense of timelessness. The coffee shop's branding is all about "building and
              growing brick by brick," which is reflected in everything from the menu to the promotional materials. This place gives off the sense of having a
              long-term vision where quality and craftsmanship are valued above all else.</p>
            <p>You get the distinct sense that you are a part of a particular community of coffee enthusiasts who like the finer things in life as you place your
              drink
              order. You know you're in excellent hands at this coffee shop created to last, whether you're here to work, catch up with friends, or simply
              savor a
              freshly brewed cup of coffee. More than just a spot to get your caffeine fix, this coffee shop serves as a hub for inspiration, community, the
              development of ideas, and relationships with people.</p>
          </div>
          <h1>Social Media</h1>
          <div className="imge vid">
            <div className="f soilcampHeight">
              <video src={v1 + "#t=9"} controls="controls" muted alt=""></video>
            </div>
          </div>
          <div className="imge">
            <div className="f amb ambre">
              <LazyLoadImage effect="blur" height="100%" width="100%" src={i1} alt="" />
            </div>
            <div className="f amb ambre">
              <LazyLoadImage effect="blur" height="100%" width="100%" src={i4} alt="" />
            </div>
            <div className="f amb ambre">
              <LazyLoadImage effect="blur" height="100%" width="100%" src={i6} alt="" />
            </div>
          </div>
          <h1>Invitation AR</h1>
          <div className="imge vid">
            <div className="f">
              <video src={i7 + "#t=1"} controls="controls" muted alt=""></video>
            </div>
            <div className="f">
              <video src={i8 + "#t=1"} controls="controls" muted alt=""></video>
            </div>
          </div>
          <h1>Recent Projects</h1>
          <Recent />
        </div>
        <Contact />
        <ScrollTop /></>}
    </>
  )
}
