import React, { useEffect, useState } from "react";
import v1 from "../../assets/projects/nadiyaithra/v1.mp4";
import Nav from "./Nav";
import ScrollTop from "../ScrollTop";
import Contact from "../Contact";
import Recent from "./Recent";
import Loader from "./Loader";

export const NadiyaIthara = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Nav />
          <div className="figo">
            <div className="container">
              <div className="backImage nadiyaImgBef">
                <div className="overlay"></div>
                <h1>Nadiya Ithra VFX</h1>
              </div>
            </div>
            <h1>Story</h1>
            <div className="para">
              <p>
                Imagine a reversal of time as the futuristic Ithraa building
                transforms back to the Nabatean era, offering a unique twist on
                Saudi Arabia's historical tapestry. a concept of a restaurent
                that was hosted for 2 weeks in ithra called nadiya represets the
                saudi clunary.
              </p>
            </div>
            <div className="imge">
              <div className="f archiStyle">
                <video
                  src={v1 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
            </div>
            <h1>Recent Projects</h1>
            <Recent />
          </div>
          <Contact />
          <ScrollTop />
        </>
      )}
    </>
  );
};
