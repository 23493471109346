import React, { useEffect, useState } from 'react'
import v1 from "../../assets/projects/archi/a1.mp4"
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'


const Archi = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage archiImgBef">
                            <div className="overlay"></div>
                            <h1>ARCHI</h1>
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f archiStyle" >
                            <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}

export default Archi