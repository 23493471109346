import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// social media
import so1 from "../../assets/projects/trb/social/1.jpeg"
import so2 from "../../assets/projects/trb/social/2.jpeg"
import so3 from "../../assets/projects/trb/social/3.jpeg"
import so4 from "../../assets/projects/trb/social/4.jpeg"
import so5 from "../../assets/projects/trb/social/5.jpeg"
export const Trbscial = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                <div className="container">
                        <div className="backImage trbImgBef">
                            <div className="overlay"></div>
                            <h1>The Royal Burger Branding</h1>
                        </div>
                    </div>
                    <h1>Brand Story</h1>
                    <div className="intro">
                        <p>What brings us together as humans? Is it the artist unveiling a new masterpiece at the art gallery? Is it the football player
                            looking to score the winner in a cup final? Is it the author looking to release the much-awaited book copy? Is it the musician
                            scheduled to perform at a sold-out concert? Or, is it the farmer looking to bring out the season’s harvest?</p>
                        <p>At The Royal Burger, we believe all bring us together. But nothing more than a tasty juicy burger, made with love and a bit of
                            crunch. TRB; connecting people over deliciously made burgers!</p>
                    </div>
                    <h1>Branding</h1>
                    <div style={{ paddingTop: 0 }} className="logos">
                        <div className="imageLogo ambImgLogo">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={so5} alt="" />
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img src={so1} alt="" />
                            <img src={so2} alt="" />
                            <img src={so3} alt="" />
                            <img src={so4} alt="" />
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}
