import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "1y01ra9l", // you can find this in sanity.json
  dataset: "production", // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  apiVersion: "2022-02-03",
});

export { client };
