import React from 'react'
import { motion } from 'framer-motion';
import { TitleAnim } from '../Animation';
import UseScroll from './UseScroll'

const Contact = () => {
    const [element, controls] = UseScroll()
    return (
        <motion.div animate={controls} initial="hidden" ref={element} id='contact' className='container contact'>
            <div className="componentTitle ">
                <div className="timeLine"> 6 / 7</div>
            </div>
            <div className="contact_us">
                <div className="hide">
                    <motion.h1 variants={TitleAnim}>CONTACT US </motion.h1>
                </div>
                <div className="links">
                    <div className="mail">
                        <a href="mailto:hello@commonplacestudio.com">Mail</a>
                    </div>
                    <motion.div variants={TitleAnim} className="social_link">
                        <motion.a variants={TitleAnim} href="https://instagram.com/atcommonplace?igshid=YmMyMTA2M2Y=">Instagram</motion.a>
                        <motion.a variants={TitleAnim} href="https://www.linkedin.com/company/commonplace-studio/" target="_blank" >Linkedin</motion.a>
                        <motion.a variants={TitleAnim} href="tel:+966554093749">Phone</motion.a>
                        <motion.a variants={TitleAnim} href="https://superfuture.com/2022/04/new-shops/al-khobar-ambiance-restaurant-renewal/">Press</motion.a>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    )
}

export default Contact