import checkArrow from "../assets/arrow.png"
import { useState } from 'react'
import { Link } from 'react-router-dom'
export const PassCheck = () => {
    const [pass, setkpass] = useState()
    const [isOkay, checkIsOkay] = useState(false)
    const PassChecker = (e) => {
        if (pass === '20232024') {
            checkIsOkay(true)
            // setkpass("")
        } else {
            checkIsOkay(false)
        }
    }
    return (
        <div className='passCheck' >
            <input type="password" placeholder='Enter Password...' onChange={(e) => { setkpass(e.target.value) }} onBlur={() => { PassChecker() }} name="" id="" />
            <Link to={isOkay && './jewar'}>
                <img src={checkArrow} onClick={() => { PassChecker()}} alt="" srcset="" />
            </Link>
        </div>
    )
}

