import React, { useEffect, useState } from 'react'
import Recent from './Recent'
import Loader from './Loader'
import Contact from '../Contact'
import ScrollTop from '../ScrollTop'
import Nav from './Nav'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// 3d imagies
import threeD1 from "../../assets/projects/fleurology/3d/Poster1.png"
import threeD2 from "../../assets/projects/fleurology/3d/Poster2.png"
import threeD3 from "../../assets/projects/fleurology/3d/Poster3.png"
import threeD5 from "../../assets/projects/fleurology/3d/Poster5.png"
import threeD8 from "../../assets/projects/fleurology/3d/Poster-6.png"
import threeD9 from "../../assets/projects/fleurology/3d/Poster-7.png"
//merch imagies
import m1 from "../../assets/projects/fleurology/MERCH/1-2.png"
import m2 from "../../assets/projects/fleurology/MERCH/1-3.png"
import m3 from "../../assets/projects/fleurology/MERCH/1-4.png"
import m4 from "../../assets/projects/fleurology/MERCH/1-5.png"
import m5 from "../../assets/projects/fleurology/MERCH/1-6.png"
import m9 from "../../assets/projects/fleurology/MERCH/1-7.png"
import m7 from "../../assets/projects/fleurology/MERCH/1-8.png"
import m8 from "../../assets/projects/fleurology/MERCH/1-9.png"
import m6 from "../../assets/projects/fleurology/MERCH/1-1 Compiled Motion.mp4"
// social
// video
import s1 from "../../assets/projects/fleurology/SOCIAL MEDIA/2-1.png"
import s2 from "../../assets/projects/fleurology/SOCIAL MEDIA/2-2.png"
import s3 from "../../assets/projects/fleurology/SOCIAL MEDIA/Post1.png"
import s4 from "../../assets/projects/fleurology/SOCIAL MEDIA/Post2.png"
import s8 from "../../assets/projects/fleurology/SOCIAL MEDIA/social2.jpeg"
import s9 from "../../assets/projects/fleurology/SOCIAL MEDIA/WhatsApp Video 2023-07-06 at 8.52.23 PM.mp4"
import s10 from "../../assets/projects/fleurology/SOCIAL MEDIA/4-1.mp4"
import s5 from "../../assets/projects/fleurology/SOCIAL MEDIA/4-2.mp4"
// flower
import f1 from "../../assets/projects/fleurology/flower/f1.png"
import f2 from "../../assets/projects/fleurology/flower/f2.png"
import f3 from "../../assets/projects/fleurology/flower/f3.png"
import f4 from "../../assets/projects/fleurology/flower/f4.png"
import A1 from "../../assets/projects/fleurology/AR/1.mp4"
import vfx1 from "../../assets/projects/fleurology/vfx/vfx1.mp4"

export const Fleurology = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage fluImgBef">
                            <div className="overlay"></div>
                            <h1>FLEUROLOGY</h1>
                        </div>
                    </div>
                    <h1>Fleurology Story</h1>
                    <div className="intro headerWithImage">
                        <div className="para">
                            <p>I see flowers everywhere:
                                In the concrete sidewalk cracks.
                                I see them sprouting from the doorways,
                                And blooming from subway tracks.</p>
                            <p>There are flowers at every corner.
                                The old, the new, the strange.
                                A fantastical floral fantasy
                                Come alive like a dream on display.
                                There are flowers wherever you go,
                                In the streets, in gardens, in the air,
                                In memories, in dreams, in skylines,</p>
                            <p>There are gardens within the clouds,
                                And there are petals inside the breeze.
                                I look to see a sky full of flowers,
                                All the flowers pulled out from a dream.</p>
                            <p>I see flowers everywhere:
                                In my memory and my past.
                                Between old walls and aged floorboards,
                                In my mother’s old garden grass.</p>
                            <p>Can you see them under your carpets?
                                Behind bookshelves and under your bed?
                                In the old letters you kept in a hatbox,
                                There are flowers waiting there now instead.</p>
                            <p>I see them twirling in the summer,
                                But they can dance in winter, too.
                                In sunshine or the snowfall,
                                The flowers still come out to bloom.</p>
                            <p>They pour from your open windows,
                                They rush out from your bottles and cans.
                                I see the buds gathering in your shoes,
                                I see them bursting out from your hands.</p>
                            <p>I see flowers everywhere. </p>
                        </div>

                        <div className="headingVid">
                            <video src={m6 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f1} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f2} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f3} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f4} alt="" />
                        </div>
                    </div>
                    <h1>Branding</h1>
                    <div className="imge">

                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={threeD1} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={threeD2} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={threeD3} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={threeD5} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={threeD8} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage src={threeD9} effect="blur" alt="" />
                        </div>
                    </div>

                    <h1>Merch</h1>
                    <div className="imge">
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m7} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m8} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m9} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m1} alt="" />
                        </div>

                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m2} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m3} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m4} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m5} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={s8} alt="" />
                        </div>

                    </div>
                    <h1>Social Media </h1>
                    <div className="imge vid" style={{ paddingTop: "0px" }} >
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s1} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s2} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s3} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s4} alt="" />
                            </div>
                            <div className="f fluFlexTwo">
                                <video src={s5 + "#t=1"} controls="controls" alt="" ></video>
                            </div>
                            <div className="f fluFlex fluFlexTwo" >
                                <video src={s10 + "#t=1"} controls="controls" alt="" ></video>
                            </div>
                        </div>
                    </div>


                    <h1>Augmented Reality</h1>
                    <div className="imge">
                        <div className="f">
                            <video src={A1 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={s9 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <h1>VFX</h1>
                    <div className="imge vid" style={{ paddingTop: "0px" }} >
                        <div className="imge fleImg">
                            <div className="f fluFlex fluFlexTwo" >
                                <video src={vfx1 + "#t=1"} controls="controls" alt="" ></video>
                            </div>
                        </div>
                    </div>

                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}

        </>
    )
}
