import React, { useEffect, useState } from 'react'

import g1 from "../../assets/projects/goya/cover.JPG"
import g2 from "../../assets/projects/goya/pack1.png"
import sin1 from "../../assets/projects/goya/sin1.jpg"
import sin2 from "../../assets/projects/goya/sin2.jpg"
import sin3 from "../../assets/projects/goya/sin3.mp4"
import te1 from "../../assets/projects/goya/t1.mp4"
import te2 from "../../assets/projects/goya/t2.mp4"
import te3 from "../../assets/projects/goya/t3.mp4"

import so1 from "../../assets/projects/goya/social/so1.png"
import so2 from "../../assets/projects/goya/social/so2.png"
import so3 from "../../assets/projects/goya/social/so3.mp4"

import me1 from "../../assets/projects/goya/merch/me1.jpg"
import me2 from "../../assets/projects/goya/merch/me2.jpg"
import me3 from "../../assets/projects/goya/merch/me3.jpg"

import ph1 from "../../assets/projects/goya/photography/ph1.jpg"
import ph2 from "../../assets/projects/goya/photography/ph2.jpg"
import ph3 from "../../assets/projects/goya/photography/ph3.jpg"
import ph4 from "../../assets/projects/goya/photography/ph4.jpg"
import ph5 from "../../assets/projects/goya/photography/ph5.png"
import ph6 from "../../assets/projects/goya/photography/ph6.png"
import ph7 from "../../assets/projects/goya/photography/ph7.png"
import ph8 from "../../assets/projects/goya/photography/ph8.png"


import ScrollTop from '../ScrollTop'
import { Link } from 'react-router-dom'
import coverA from "../../assets/projects/amb/brand/9.jpg"
import coverR from "../../assets/projects/ray/paper/3.jpg"
import coverS from "../../assets/projects/shukran/IMG_7587.JPG"
import coverSoil from "../../assets/projects/soil/02-min.jpeg"
import Nav from './Nav'
import Contact from '../Contact'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from './Loader'

const Goya = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <>
                <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage goyaImgBef">
                            <div className="overlay"></div>
                            <h1>GO-YA</h1>
                        </div>
                    </div>
                    <h1>Goya Story</h1>
                    <div className="intro">
                        <p>Do you hear that? That faint sound? It’s coming closer… what could it be? It sounds so familiar… the memory is coming back to you. You remember hot summers and asking your mom for some change. Then, running along the beach yelingl, “Ice cream!” after the man with a giant cart, carrying your favorite frozen treat.</p>
                        <br />
                        <p>He’s back! He’s finally back in the neighborhood. It’s the chance you’ve been waiting for, dreaming about, hoping to re-live. He’s returned, umbrella in hand and ice cream at the ready. He’s even brought his big chair out to stay out all day, enjoying the sun and the happiness he’s bringing. But it’s only for a short time, so catch him while you can! Summer really couldn’t get any better.</p>
                    </div>

                    <h1>Branding and packaging</h1>

                    <div className="imge">
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={g1} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={g2} alt="" />
                        </div>
                    </div>

                    <h1>The signage</h1>
                    <div className="imge goyaImg">
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={sin1} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={sin2} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <video style={{ padding: "0.5rem" }} className="res" src={sin3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>

                    <h1>Teasers</h1>
                    <div className="imge vid">
                        <div className="f autoHeight">
                            <video src={te1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f autoHeight">
                            <video src={te2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f autoHeight">
                            <video src={te3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>

                    <h1>Social Media</h1>
                    <div className="imge">
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={so1} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={so2} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <video style={{ padding: "0.5rem" }} src={so3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>

                    <h1>Merch and invitation</h1>
                    <div className="imge">
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={me1} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={me2} alt="" />
                        </div>
                        <div className="f amb ambre">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={me3} alt="" />
                        </div>
                    </div>

                    <h1>Photography</h1>
                    <div className="imge">
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph1} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph2} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph3} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph4} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph5} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph6} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph7} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={ph8} alt="" />
                        </div>
                    </div>

                    <h1>Recent Projects</h1>
                    <div className="imge">
                        <Link to="/ambiance">
                            <div className=" recproject">
                                <h1>Ambiance</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverA} alt="" />
                            </div>
                        </Link>
                        <Link to="/ray">
                            <div className=" recproject">
                                <h1>Ray</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverR} alt="" />
                            </div>
                        </Link>
                        <Link to="/shukran">
                            <div className=" recproject">
                                <h1>Shukran</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverS} alt="" />
                            </div>
                        </Link>
                        <Link to="/soil">
                            <div className=" recproject">
                                <h1>Soil</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverSoil} alt="" />
                            </div>
                        </Link>
                    </div>

                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}

export default Goya