import React, { useEffect, useState } from "react";
import v1 from "../../assets/projects/space/1.mp4";
import v2 from "../../assets/projects/space/as1.mp4";
import v3 from "../../assets/projects/space/as1.mp4";
import Nav from "./Nav";
import ScrollTop from "../ScrollTop";
import Contact from "../Contact";
import Recent from "./Recent";
import Loader from "./Loader";

export const Astronaut = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Nav />
          <div className="figo">
            <div className="container">
              <div className="backImage spaceImgBef">
                <div className="overlay"></div>
                <h1>Astronaut VFX</h1>
              </div>
            </div>
            <h1>Story</h1>
            <div className="para">
              <p>
                We designed a captivating concept to celebrate Saudis venturing
                into space—an astronaut emerging from the largest digital screen
                in Khobar. Our idea was beautifully simple: the astronaut would
                appear, announcing that our nation's ﬂag had reached the cosmos.
                Moreover, our main focus was on utilizing the screen in a unique
                way, creating a stunning 3D illusion that gave the impression of
                the astronaut breaking through the building itself.
              </p>
            </div>
            <div className="imge">
              <div className="f archiStyle">
                <video
                  src={v1 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
              <div className="f archiStyle">
                <video
                  src={v2 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
              <div className="f archiStyle">
                <video
                  src={v3 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
            </div>
            <h1>Recent Projects</h1>
            <Recent />
          </div>
          <Contact />
          <ScrollTop />
        </>
      )}
    </>
  );
};
