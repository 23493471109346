import React, { useEffect, useState } from "react";
import archi from "../assets/projects/archi/a6.jpg";
import nadiya from "../assets/projects/nadiyaithra/main.png";
import soilvfx from "../assets/projects/soilvfx/soil-pic.png";
import chemistry from "../assets/projects/chemistry/chem-pic.png";
import oomiya from "../assets/projects/omyaabranding/1B.jpg";
import plentysld from "../assets/projects/plentysld/plenty-pic.png";
import webrew from "../assets/projects/webbrew/webrew-pic.png";
import coverF from "../assets/projects/figo/logo.jpg";
import coverA from "../assets/projects/amb/brand/9.jpg";
import coverR from "../assets/projects/ray/paper/3.jpg";
import coverS from "../assets/projects/shukran/IMG_7587.JPG";
import coverC from "../assets/projects/cone/c1.jpg";
import coverG from "../assets/projects/goya/cover.JPG";
import coverSoil from "../assets/projects/soil/02-min.jpeg";
import jewarCover from "../assets/projects/jewar/branding.jpg";
import adzoneCover from "../assets/projects/adzone/4.png";
import tonyCover from "../assets/projects/tony/social/2.jpg";
import spaceCover from "../assets/projects/space/2.png";
import takeCover from "../assets/projects/take/photos/15.png";
import coffeaCover from "../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/1.png";
import fleuCover from "../assets/projects/fleurology/SOCIAL MEDIA/social2.jpeg";
import goyasocialCover from "../assets/projects/goyasocial/month1/image/3.png";
import trbCover from "../assets/projects/trb/month1/image/2.jpg";
import soilCampCover from "../assets/projects/so1.jpg";
import trBranding from "../assets/projects/trb/branding.jpg";
import { Link } from "react-router-dom";
// lazt loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { client } from "../client";
import imageUrlBuilder from "@sanity/image-url";

const Project = () => {
  const [projects, setProjects] = useState([]);

  function urlFor(source) {
    return imageUrlBuilder(client).image(source);
  }

  useEffect(() => {
    (async () => {
      try {
        const data = await client.fetch(
          `
            *[_type == "project"]{
              _id,
              name,
              thumbnailImage,
              sections[]{
                sectionName,
                images[],
                videos[]{
                  _type,
                  asset-> {
                    url,
                    mimeType
                  }
                },
                sectionDescription
              }
            }
          `
        );

        setProjects(data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <div className="projectBox">
        {projects
          .slice()
          .reverse()
          .map(({ _id, name, thumbnailImage }) => {
            return (
              <Link to={`/projects/${_id}`} id={_id}>
                <div className="projectContent">
                  <div className="heading">
                    <h2>{name}</h2>
                  </div>
                  <div className="imageBox">
                    {thumbnailImage ? (
                      <LazyLoadImage
                        effect="blur"
                        height="100%"
                        width="100%"
                        src={urlFor(thumbnailImage).url()}
                        alt=""
                      />
                    ) : null}
                  </div>
                </div>
              </Link>
            );
          })}

        <Link to="/plentysld">
          <div className="projectContent">
            <div className="heading">
              <h2>Plenty SLD's</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={plentysld}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/nadiyaithra">
          <div className="projectContent">
            <div className="heading">
              <h2>Nadiya Ithra</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={nadiya}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/chemistry">
          <div className="projectContent">
            <div className="heading">
              <h2>Chemistry Alahssa Jazan VFX</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={chemistry}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/soilvfx">
          <div className="projectContent">
            <div className="heading">
              <h2>Soil VFX</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={soilvfx}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/webrew">
          <div className="projectContent">
            <div className="heading">
              <h2>Webrew VFX</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={webrew}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/oomiya">
          <div className="projectContent">
            <div className="heading">
              <h2>Oomiya Branding</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={oomiya}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/jewar">
          <div className="projectContent">
            <div className="heading">
              <h2>Jewar</h2>
            </div>
            <div className="imageBox containImg">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={jewarCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/fleurology">
          <div className="projectContent">
            <div className="heading">
              <h2>Fleurology</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={fleuCover}
                alt=""
              />
            </div>
          </div>
        </Link>

        <Link to="/astronaut">
          <div className="projectContent">
            <div className="heading">
              <h2>Astronaut vfx</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={spaceCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/coffea">
          <div className="projectContent">
            <div className="heading">
              <h2>Coffea</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coffeaCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/soillaunch">
          <div className="projectContent">
            <div className="heading">
              <h2>Soil launch campaign</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={soilCampCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/take">
          <div className="projectContent">
            <div className="heading">
              <h2>Take Branding</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={takeCover}
                alt=""
              />
            </div>
          </div>
        </Link>
      </div>

      <div className="projectBox">
        <Link to="/trbbranding">
          <div className="projectContent">
            <div className="heading">
              <h2>The Royal Burger Branding</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={trBranding}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/trbsocial">
          <div className="projectContent">
            <div className="heading">
              <h2>The Royal Burger Social Media</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={trbCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/tony">
          <div className="projectContent">
            <div className="heading">
              <h2>Tony Social Media</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={tonyCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/adzone">
          <div className="projectContent">
            <div className="heading">
              <h2>Adzone</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={adzoneCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/goyasocial">
          <div className="projectContent">
            <div className="heading">
              <h2>Goya Social Media</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={goyasocialCover}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/conezone">
          <div className="projectContent">
            <div className="heading">
              <h2>Cone Zone</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverC}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/goya">
          <div className="projectContent">
            <div className="heading">
              <h2>GO-YA</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverG}
                alt=""
              />
            </div>
          </div>
        </Link>
      </div>
      <div className="projectBox">
        <Link to="/figo">
          <div className="projectContent">
            <div className="heading">
              <h2>FIGO</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage effect="blur" height="100%" src={coverF} alt="" />
            </div>
          </div>
        </Link>
        <Link to="/ambiance">
          <div className="projectContent">
            <div className="heading">
              <h2>ambiance</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverA}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/ray">
          <div className="projectContent">
            <div className="heading">
              <h2>rays</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverR}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/soil">
          <div className="projectContent">
            <div className="heading">
              <h2>soil</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverSoil}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/shukran">
          <div className="projectContent">
            <div className="heading">
              <h2>shukran</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={coverS}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/archi">
          <div className="projectContent">
            <div className="heading">
              <h2>Archi</h2>
            </div>
            <div className="imageBox">
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={archi}
                alt=""
              />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Project;
