import React from 'react'
import { motion } from 'framer-motion'
import { TitleAnim } from '../Animation'
import UseScroll from './UseScroll'
import Project from './Project';

const Clients = () => {
    const [element, controls] = UseScroll()
    return (
        <motion.div animate={controls} initial="hidden" ref={element} id='client' className='container clients ' >
            <div className="componentTitle ">
                <div className="timeLine">4 / 7</div>
            </div>
            <div className="clients_section">
                <div className="client_title_tab">
                    <motion.div variants={TitleAnim} className="client_title">
                        <div className="hide">
                            <motion.h1 variants={TitleAnim} >clients who</motion.h1>
                        </div>
                        <div className="hide">
                            <motion.h1 variants={TitleAnim}>we’ve worked with </motion.h1>
                        </div>
                    </motion.div>
                    <p>Here are some of the customers that we have enjoyed working with! </p>
                </div>
            </div>
            <Project />
        </motion.div>
    )
}

export default Clients