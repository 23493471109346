import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// logos
import l1 from "../../assets/projects/jewar/arabic/jewar logo variation mockup.png"
import l3 from "../../assets/projects/jewar/arabic/jewar logo variation mockup11.png"
import l7 from "../../assets/projects/jewar/arabic/jewar logo variation mockup4.png"
import l10 from "../../assets/projects/jewar/arabic/jewar logo variation mockup7.png"
// logo
import lo1 from "../../assets/projects/jewar/logo/Artboard 1 copy 2.png"
import lo2 from "../../assets/projects/jewar/logo/Artboard 1 copy 3.png"
import lo3 from "../../assets/projects/jewar/logo/Artboard 1 copy 4.png"
import lo4 from "../../assets/projects/jewar/logo/Artboard 1 copy 5.png"
// elogo
import lo5 from "../../assets/projects/jewar/elogo/Brown-V1.png"
import lo6 from "../../assets/projects/jewar/elogo/Dust-V1.png"
import lo7 from "../../assets/projects/jewar/elogo/Green-V1.png"
import lo8 from "../../assets/projects/jewar/elogo/Orange-V1.png"
// socia;
import s1 from "../../assets/projects/jewar/social/boutique-post3.mp4"
import s2 from "../../assets/projects/jewar/social/Jewar-Post1.mp4"
import s4 from "../../assets/projects/jewar/social/Yard-Post2.mp4"

// vfx
import vf1 from "../../assets/projects/jewar/VFX/dron1.mp4"
import vf2 from "../../assets/projects/jewar/VFX/jewar1.mp4"
import vf3 from "../../assets/projects/jewar/VFX/yard1.mp4"

import branding1 from "../../assets/projects/jewar/branding.jpg"
import story from "../../assets/projects/jewar/story.jpeg"

export const Jewar = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage jewarImgBef">
                            <div className="overlay"></div>
                            <h1>Jewar</h1>
                        </div>
                    </div>
                    <h1>Jewar Story</h1>
                    <div className="intro">
                   <img src={story} alt="" />
                    </div>
                    <h1>Branding</h1>
                    <div className="imge">
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo1} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo2} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo3} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo4} alt="" />
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo8} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo7} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo6} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={lo5} alt="" />
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f jewarContain">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={branding1} alt="" />
                        </div>

                    </div>
                    <div className="imge">
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={l1} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={l3} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={l10} alt="" />
                        </div>
                        <div className="f amb ">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={l7} alt="" />
                        </div>
                    </div>

                    <h1>Social Media</h1>
                    <div className="imge goyaImg">
                        <div className="f amb ambre jewarHeight">
                            <video style={{ padding: "0.5rem" }} className="res" src={s1 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f amb ambre jewarHeight">
                            <video style={{ padding: "0.5rem" }} className="res" src={s2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f amb ambre jewarHeight">
                            <video style={{ padding: "0.5rem" }} className="res" src={s4 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>


                    <h1>VFX</h1>
                    <div className="imge vid">
                        <div className="f autoHeight">
                            <video src={vf1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f autoHeight">
                            <video src={vf2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f autoHeight">
                            <video src={vf3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop />
            </>}
        </>
    )
}
