import React, { useEffect, useState } from 'react'
import f1 from "../../assets/projects/s1.jpg"
import f2 from "../../assets/projects/s2.jpg"
import ScrollTop from '../ScrollTop'
import sh1 from "../../assets/projects/shukran/IMG_7581.JPG"
import sh2 from "../../assets/projects/shukran/IMG_7587.JPG"
import { Link } from 'react-router-dom'
import coverF from "../../assets/projects/figo/logo.jpg"
import coverA from "../../assets/projects/amb/brand/9.jpg"
import coverR from "../../assets/projects/ray/paper/3.jpg"
import coverSoil from "../../assets/projects/soil/02-min.jpeg"
import Nav from './Nav'
import Contact from '../Contact'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from './Loader'


const Shukran = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <>
                <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage shukranImgBef">
                            <div className="overlay"></div>
                            <h1>SHUKRAN BOOFIYA</h1>
                        </div>
                    </div>
                    <div className="intro">
                        <p>
                            You'll feel like you're back in the old days. Have an experience of a healthy homey breakfast or snack outside your home. Have it fresh, fast, and served deliciously! It can only be found and experienced at Shukran Boofiya!
                        </p>
                    </div>
                    <div className="imge vid">
                        {/* <div className="take1"> */}
                        <div className="f fcheck">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f1} alt="" />
                        </div>
                        <div className="f fcheck">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={sh1} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f2} alt="" />
                        </div>

                        {/* </div> */}
                        {/* <div className="take2"> */}

                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={sh2} alt="" />
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="services">
                        <h1>SERVICES:</h1>
                        <ul>
                            <li>Interior & exterior design</li>
                            <li>Art direction</li>
                            <li>Branding</li>
                        </ul>
                    </div>
                    <h1>Recent Projects</h1>
                    <div className="imge">
                        <Link to="/figo">
                            <div className=" recproject">
                                <h1>Figo</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverF} alt="" />
                            </div>
                        </Link>
                        <Link to="/ray">
                            <div className=" recproject">
                                <h1>Ray</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverR} alt="" />
                            </div>
                        </Link>
                        <Link to="/ambiance">
                            <div className=" recproject">
                                <h1>Ambiance</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverA} alt="" />
                            </div>
                        </Link>
                        <Link to="/soil">
                            <div className=" recproject">
                                <h1>Soil</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverSoil} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}

export default Shukran