import React, { useEffect, useState } from 'react'
import v11 from "../../assets/projects/archi/a2.jpg"
import v2 from "../../assets/projects/archi/a3.jpg"
import v3 from "../../assets/projects/archi/a4.jpg"
import v4 from "../../assets/projects/archi/a5.jpg"
import ScrollTop from '../ScrollTop'
import Nav from './Nav'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from "./Loader"

// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Cone = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage coneImgBef">
                            <div className="overlay"></div>
                            <h1>Cone zone</h1>
                        </div>
                    </div>
                    <h1>Cone Story</h1>
                    <div className="intro">
                        <p>
                            It's the Holy month of Ramadan, a reminder to give and be a blessing. Cone Zone embraces life's good things and celebrates them with friends, family, and our community.
                        </p>
                    </div>
                    <div className="imge vid">
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={v11} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={v2} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={v3} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={v4} alt="" />
                        </div>
                    </div>
                    
                    <div className="services">
                        <h1>SERVICES:</h1>
                        <ul>
                            <li>Ramadan packaging</li>
                            <li>Art direction</li>
                        </ul>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}


        </>
    )
}

export default Cone