import React, { useEffect, useState } from 'react'
import f1 from "../../assets/projects/amb/int/amb1.jpg"
import f2 from "../../assets/projects/amb/int/amb2.jpg"
import f3 from "../../assets/projects/amb/int/amb3.jpg"
import f4 from "../../assets/projects/amb/int/amb4.jpg"
import r1 from "../../assets/projects/amb/brand/1.jpg"
import r2 from "../../assets/projects/amb/brand/2.jpg"
import r3 from "../../assets/projects/amb/brand/3.jpg"
import r4 from "../../assets/projects/amb/brand/4.jpg"
import r5 from "../../assets/projects/amb/brand/5.jpg"
import r6 from "../../assets/projects/amb/brand/6.jpg"
import r9 from "../../assets/projects/amb/brand/9.jpg"
import r10 from "../../assets/projects/amb/brand/10.jpg"
import ambv2 from "../../assets/projects/amb/invitation/ambv2.mp4"
import ambv3 from "../../assets/projects/amb/invitation/ambv3.mp4"
import ambImg1 from "../../assets/projects/amb/invitation/ambv1.jpg"
import v1 from "../../assets/projects/amb/video/v1.mp4"
import v2 from "../../assets/projects/amb/video/v2.mp4"
import v3 from "../../assets/projects/amb/video/v3.mp4"
import v5 from "../../assets/projects/amb/video/v5.mp4"
import in1 from "../../assets/projects/amb/invitation/1.jpg"
import in2 from "../../assets/projects/amb/invitation/2.jpg"
import in3 from "../../assets/projects/amb/invitation/3.jpg"
import in4 from "../../assets/projects/amb/invitation/7.jpg"
import { motion } from 'framer-motion'
import ScrollTop from '../ScrollTop'
import Nav from './Nav'
import Contact from '../Contact'

// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Recent from './Recent'
import Loader from './Loader'

const Ambiance = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])

    return (
        <>
            {loading ? <Loader /> : <><Nav />
                <motion.div className='figo'>
                    <div className="container">
                        <div className="backImage ambImgBef">
                            <div className="overlay"></div>
                            <h1>AMBIANCE</h1>
                        </div>
                    </div>
                    <h1>Ambiance Story</h1>
                    <div className="intro">
                        <p>Can you see me?
                            feel me?
                            hear me?
                            taste me?
                            smell me?
                            of course, you can. even if you don’t know it yet.

                            but what am i? or rather, who am i?
                            i’m the journey, the experience, and now, i’m a part of you.
                            i’ll help you lose yourself in the space, then find yourself in your senses.

                            i’ll take you to another level [sometimes literally].i’ll show you a whole new world, one
                            you won’t want to leave.
                            i’m the ambiance.

                        </p>
                    </div>
                    <div className="imge vid">
                        <div className="f">
                            <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={v2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={v3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f">
                            <video src={v5 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <h1>Art Direction</h1>
                    <h2>Re-branding & packaging</h2>
                    <div className="imge">
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r1} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r2} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r9} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r10} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r3} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r4} alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage src={r5} effect="blur" alt="" />
                        </div>
                        <div className="f amb">
                            <LazyLoadImage effect="blur" src={r6} alt="" />
                        </div>
                    </div>
                    <h1>Interior masterpiece</h1>
                    <div className="imge">
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f1} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f2} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f3} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f4} alt="" />
                        </div>
                    </div>
                    <h1>Teaser</h1>
                    <div className="imge imgeTeaser">
                        <div className="f amb inter resDiv res2 ambset3">
                            <LazyLoadImage effect="blur" src={ambImg1} alt="" />
                        </div>
                        <div className="f amb inter resDiv res3 ambset2">
                            <video style={{ padding: "0.5rem" }} class="res" src={ambv2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <h1>Invitation</h1>
                    <div style={{ paddingTop: 0 }} className="logos">
                        <div className="imageLogo ambImgLogo rellamb">
                            <video src={ambv3 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img effect="blur" src={in1} alt="" />
                            <img effect="blur" src={in2} alt="" />
                            <img effect="blur" src={in3} alt="" />
                            <img effect="blur" src={in4} alt="" />
                        </div>
                    </div>
                    <div className="services">
                        <h1>SERVICES:</h1>
                        <ul>
                            <li>Re-Branding and packaging</li>
                            <li>Brand story</li>
                            <li>Interior element “the Cube design”</li>
                            <li>Photography direction</li>
                            <li>Invitation</li>
                            <li>Teaser</li>
                        </ul>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                    <ScrollTop />
                </motion.div>
                <Contact /></>}

        </>
    )
}

export default Ambiance