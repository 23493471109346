import React, { useEffect, useState } from "react";
import v1 from "../../assets/projects/chemistry/v1.mp4";
import v2 from "../../assets/projects/chemistry/v2.mp4";
import v3 from "../../assets/projects/chemistry/v3.mp4";

import Nav from "./Nav";
import ScrollTop from "../ScrollTop";
import Contact from "../Contact";
import Recent from "./Recent";
import Loader from "./Loader";

export const Chemistry = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Nav />
          <div className="figo">
            <div className="container">
              <div className="backImage chemistryImgBef">
                <div className="overlay"></div>
                <h1>Chemistry Alahssa Jazan VFX</h1>
              </div>
            </div>
            <h1>Story</h1>
            <div className="para">
              <p>
                In our F&B sector specifically this project Chemistry coffee, we
                seamlessly blend cultures, choosing heritage locations like
                Jabal Gara in Alahsa and the traditional city of Jazan for
                representation.
              </p>
            </div>
            <div className="imge">
              <div className="f archiStyle">
                <video
                  src={v1 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
              <div className="f archiStyle">
                <video
                  src={v2 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
              <div className="f archiStyle">
                <video
                  src={v3 + "#t=1"}
                  controls="controls"
                  muted
                  alt=""
                ></video>
              </div>
            </div>
            <h1>Recent Projects</h1>
            <Recent />
          </div>
          <Contact />
          <ScrollTop />
        </>
      )}
    </>
  );
};
