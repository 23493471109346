import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
// media
import r2 from "../../assets/projects/tony/social/v2.mp4"
import r3 from "../../assets/projects/tony/social/v3.mp4"
import r4 from "../../assets/projects/tony/social/Tonys Burger Video.m4v"
import p1 from "../../assets/projects/tony/social/1.jpg"
import p2 from "../../assets/projects/tony/social/2.jpg"
import p3 from "../../assets/projects/tony/social/3.jpg"
import p4 from "../../assets/projects/tony/social/4.jpg"
import p5 from "../../assets/projects/tony/social/6.jpg"
import p6 from "../../assets/projects/tony/social/5.jpg"

import 'react-lazy-load-image-component/src/effects/blur.css';
export const Tony = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage tonyImgBef">
                            <div className="overlay"></div>
                            <h1>Tony Social Media</h1>
                        </div>
                    </div>
                    <div className="intro">
                        <p>Our campaign for the Tony's brand aimed to evoke nostalgia by taking audiences back to the
                            80s-70s. Through carefully designed visuals and social media content, we recreated the vintage
                            look and feel of the era. Highlighting the campaign were heartwarming videos featuring a
                            grandma, expertly crafted to resemble authentic 80s-70s footage. Additionally, we created a
                            captivating VFX video showcasing Tony interacting with fans in the vibrant streets of Khobar.
                            Overall, our campaign successfully captured the essence of the 80s-70s, transporting audiences
                            to a bygone era of cherished memories.
                        </p>
                    </div>
                    <div className="imge vid">
                        <div className="f autoHeight">
                            <video src={r3 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f autoHeight">
                            <video src={r2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                        <div className="f autoHeight">
                            <video src={r4 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p1} alt="" />
                        </div>
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p2} alt="" />
                        </div>
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p3} alt="" />
                        </div>
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p4} alt="" />
                        </div>
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p5} alt="" />
                        </div>
                        <div className="f amb ambre tony">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={p6} alt="" />
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}
