import React from "react";

const ImagePreview = (props) => {
  const {
    asset: { imgSrc, alt },
    togglePreview,
  } = props;

  return (
    <div
      className="preview-container"
      onClick={() => togglePreview((prev) => !prev)}
    >
      <img src={imgSrc} alt={alt} />
    </div>
  );
};

export default ImagePreview;
