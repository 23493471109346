import React from 'react'
import { motion } from 'framer-motion';
import UseScroll from './UseScroll'
import { TitleAnim} from '../Animation';

const About = () => {
    const [element, controls] = UseScroll()
    return (
        <motion.div animate={controls} initial="hidden" id='about' ref={element} className='container aboutSection' >
            <div className="componentTitle ">
                <div className="timeLine">3 / 7</div>
            </div>
            <div className="about">
                <div className="hide">
                    <motion.h1 variants={TitleAnim} >ABOUT US </motion.h1>
                </div>
                <motion.div className='hide'  >
                    <motion.p>We recognize that everyone has a gift that makes them special, and our gift is to tell stories that create healthy business-client relationships. </motion.p>
                </motion.div>

                <p className='text2' >We have always been storytellers, but realized a greater purpose in helping businesses tell stories of their own. Storytelling ensures your business stands out from the crowd and creates an environment for brand love, ensuring you live every day in your truth and leave a legacy behind.</p>
            </div>
        </motion.div>
    )
}

export default About;