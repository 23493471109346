import React, { useEffect, useState } from 'react'
import Recent from './Recent'
import Loader from './Loader'
import Contact from '../Contact'
import ScrollTop from '../ScrollTop'
import Nav from './Nav'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// assets
import v1 from "../../assets/projects/adzone/10.mp4"
import v2 from "../../assets/projects/adzone/2.mp4"
// social
import s1 from "../../assets/projects/adzone/1.png"
import s2 from "../../assets/projects/adzone/11.png"
import s4 from "../../assets/projects/adzone/4.png"
import s5 from "../../assets/projects/adzone/5.jpg"
import s6 from "../../assets/projects/adzone/6.png"
import s7 from "../../assets/projects/adzone/7.png"
import s8 from "../../assets/projects/adzone/8.png"
import i1 from "../../assets/projects/adzone/intro1.mp4"
import i2 from "../../assets/projects/adzone/intro2.mp4"
// m2
import m1s1 from "../../assets/projects/adzone/m2/1.jpg"
import m1s2 from "../../assets/projects/adzone/m2/2.png"
import m1s3 from "../../assets/projects/adzone/m2/3.png"
import m1s4 from "../../assets/projects/adzone/m2/4.png"
import m1s8 from "../../assets/projects/adzone/m2/8.jpg"
import m1v1 from "../../assets/projects/adzone/m2/v1.mp4"
import m1v2 from "../../assets/projects/adzone/m2/v2.mp4"
// m3
import m2s1 from "../../assets/projects/adzone/m3/3.png"
import m2s2 from "../../assets/projects/adzone/m3/4.jpg"
import m2s3 from "../../assets/projects/adzone/m3/5.png"
import m2s5 from "../../assets/projects/adzone/m3/7.png"
import m2s6 from "../../assets/projects/adzone/m3/1.mp4"
import m2s7 from "../../assets/projects/adzone/m3/2.mp4"

export const Adzone = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage adzImgBef">
                            <div className="overlay"></div>
                            <h1>ADZONE</h1>
                        </div>
                    </div>
                    <h1> Adzone Story</h1>
                    <div className="intro headerWithImage">
                        <div className="intro para adzonepara">
                            <p>
                                Adzone is a contemplative space where people gather with a shared passion. Some practice
                                meditation with true devotion, and the details take them into a deep contemplative slumber. Some
                                have their souls renewed every day by a new adventure, while others handle all details with great
                                precision, never letting a task go out of their sight.
                            </p>
                            <p>
                                A wide future sky, a homeland made of bricks, concrete, and stone, carries us lightly and delicately.
                                We belong here, moving freely among the clouds. Our creativity is a cosmic absolute.
                            </p>
                            <p>
                                Adzone dares to look to the future without fear of ends or limits. That's why what seems surreal to
                                others' eyes is real to us. Each project is a story worth telling, and all ideas embark on a solo trip
                                through an imaginary realm that speaks of wonders.
                            </p>
                            <p>
                                Design is our first language, and we are dedicated to translating the surreal into the real.
                            </p>
                            <p>Adzone welcomes you!</p>
                        </div>

                        <div className="headingVid">
                            <video src={i1 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="headingVid">
                            <video src={i2 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <h1>Adzone Social Media First Month</h1>
                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s1} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s2} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s4} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s5} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s6} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s7} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={s8} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="f autoHeight">
                            <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f autoHeight">
                            <video src={v2 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <h1>Social Media Second Month</h1>
                    <div style={{ paddingTop: 0 }} className="logos">
                        <div className="imageLogo ambImgLogo rellamb">
                            <video src={m1v1 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img effect="blur" src={m1s1} alt="" />
                            <img effect="blur" src={m1s2} alt="" />
                            <img effect="blur" src={m1s3} alt="" />
                            <img effect="blur" src={m1s4} alt="" />
                        </div>
                    </div>
                    <div style={{ paddingTop: 0 }} className="logos"> 
                    <div className="imageLogo ambImgLogo rellamb">
                            <video src={m1v2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img effect="blur" src={m1s8} alt="" />
                        </div>
                      
                    </div>
                    <h1>Social Month Third Month</h1>
                    <div style={{ paddingTop: 0 }} className="logos">
                        <div className="imageLogo ambImgLogo">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={m2s1} alt="" />
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img src={m2s2} alt="" />
                            <img src={m2s3} alt="" />
                            {/* <img src={m2s4} alt="" /> */}
                            <img src={m2s5} alt="" />
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="f autoHeight">
                            <video src={m2s6 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f autoHeight">
                            <video src={m2s7 + "#t=1"} controls="controls" muted alt="" />
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}

        </>
    )
}
