export const PageAnimation = {
  hidden: {
    opacity: 0,
    y: -300,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      // when: "beforeChildren",
      staggerChildren: 0.25,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
};

export const TitleAnim = {
  // hidden: { opacity: 0, y: 100 },
  // show: {
  //   opacity: 1,
  //   y: 0,
  //   transition: {
  //     // type: "spring",
  //     staggerChildren: 0.25,
  //     mass: 0.5,
  //     // damping: 10,
  //     duration: 1,
  //     ease: "easeInOut",
  //   },
  // },
};

export const Fade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.85,
      ease: "easeOut",
    },
  },
};
export const PhotoAnim = {
  hidden: { opacity: 0, scale: 0 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
};
