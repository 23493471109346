import React, { useEffect, useState } from 'react'
import f1 from "../../assets/projects/figo/Fcollec1.jpg"
import f2 from "../../assets/projects/figo/Fcollec2.jpg"
import f3 from "../../assets/projects/figo/uniform1.jpeg"
import f4 from "../../assets/projects/figo/uniform2.jpg"
import f5 from "../../assets/projects/figo/uniform3.jpeg"
import f6 from "../../assets/projects/figo/uniform4.jpg"
import f7 from "../../assets/projects/figo/uniform5.png"
import f8 from "../../assets/projects/figo/uniform6.jpg"
import logo from "../../assets/projects/figo/logo.jpg"
import v1 from "../../assets/projects/figo/v1.mp4"
import v2 from "../../assets/projects/figo/v2.mp4"
import ScrollTop from '../ScrollTop'
import { Link } from 'react-router-dom'
import coverA from "../../assets/projects/amb/brand/9.jpg"
import coverR from "../../assets/projects/ray/paper/3.jpg"
import coverS from "../../assets/projects/shukran/IMG_7587.JPG"
import coverSoil from "../../assets/projects/soil/02-min.jpeg"
import Nav from './Nav'
import Contact from '../Contact'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from './Loader'

const Figoo = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <>
                <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage">
                            <div className="overlay"></div>
                            <h1>FIGO</h1>
                        </div>
                    </div>
                    
                    <h1>Figo Story</h1>
                    <div className="intro">
                        <p>You’ve stumbled across me or did you find me? it’s not easy to find me, so if you did, kudos. if you stumbled across me, you’re quite lucky. i’m figo. i’m a simple guy. i’m inspired by what others ignore. we chase the fast life and ignore the things that keep us going. we chase the goal and the dream and we forget to focus on the process. i don’t know about you, but what gets me to work everyday isn’t my dream car or the hustle and bustle of city transport- it’s the playlist that i listen to. it’s the smell of my morning coffee and it’s the thought of watching of netflix when i get home. you are now entering a place that ignores the fast life and instead focuses on those things that drive us to where we are today. the things that make you feel good. the things that make you who you are. welcome to figo’s. the home of the stretchiest pizza and your feeeel goooood space</p>
                    </div>
                    <div className="logos">
                        <div className="imageLogo figoImgLogo">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={logo} alt="" />
                        </div>
                        <div className="logoVid figoVidLogo">
                            <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                            <video src={v2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                    </div>
                    <h1>Figo’s Art Direction</h1>
                    <h2>Figo’s social media</h2>
                    <div className="imge">
                        <div className="f amb fset">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f5} alt="" />
                        </div>
                        <div className="f amb fset">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f4} alt="" />
                        </div>
                        <div className="f amb fset">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f7} alt="" />
                        </div>
                        <div className="f amb fset">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f3} alt="" />
                        </div>
                    </div>
                    <h1>Figo’s packaging</h1>
                    <div className="imge">
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f1} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f2} alt="" />
                        </div>
                    </div>
                    <h1>Figo’s uniform</h1>
                    <div className="imge">
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f8} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f6} alt="" />
                        </div>
                    </div>

                    <div className="services">
                        <h1>SERVICES :</h1>
                        <ul className='tab'>
                            <li>Branding</li>
                            <li>packaging</li>
                            <li>Brand Story</li>
                            <li>Social Media Content</li>
                        </ul>
                    </div>
                    <div className="imge">

                    </div>
                    <h1>Recent Projects</h1>
                    <div className="imge">
                        <Link to="/ambiance">
                            <div className=" recproject">
                                <h1>Ambiance</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverA} alt="" />
                            </div>
                        </Link>
                        <Link to="/ray">
                            <div className=" recproject">
                                <h1>Ray</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverR} alt="" />
                            </div>
                        </Link>
                        <Link to="/shukran">
                            <div className=" recproject">
                                <h1>Shukran</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverS} alt="" />
                            </div>
                        </Link>
                        <Link to="/soil">
                            <div className=" recproject">
                                <h1>Soil</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverSoil} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}

export default Figoo