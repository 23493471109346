import React from 'react'
import homeCam from "../assets/projects/homegif.gif";
import UseScroll from './UseScroll'
import { PhotoAnim } from '../Animation'
import { motion } from 'framer-motion'
import { TitleAnim } from '../Animation'


const HomeCam = () => {
    const [element, controls] = UseScroll()
    return (
        <motion.div animate={controls} initial="hidden" id='about' ref={element} className=' homecam container'>
            <div className="componentTitle ">
                <div className="timeLine">2 / 7</div>
            </div>
            <div className="cam">
                <div className="imgBox">
                    <motion.img src={homeCam} variants={PhotoAnim} loop autoPlay muted alt=""></motion.img>
                </div>

                <motion.div className='hide imgContent' variants={TitleAnim} animate={controls} initial="hidden" ref={element}>
                    <p>I am driven by an insatiable desire to conceive groundbreaking and unparalleled ideas that push the limits of conventional thinking.  With my highly skilled and dedicated team of professionals, we are fully equipped and eager to manifest your visionary concepts into tangible and remarkable realities.</p>
                </motion.div>
            </div>
        </motion.div>
    )
}
export default HomeCam;