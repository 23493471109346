import React from 'react'
import { motion } from 'framer-motion';
import { TitleAnim } from '../Animation';
import UseScroll from './UseScroll'


const Services = () => {
    const [element, controls] = UseScroll()
    return (
        <motion.div animate={controls} initial="hidden" ref={element} id='service' className='container service'>
            <div className="componentTitle ">
                <div className="timeLine">5 / 7</div>
            </div>
            <div className="services">
                <div className="hide">
                    <motion.h1 variants={TitleAnim} >SERVICES</motion.h1>
                </div>
                <motion.div variants={TitleAnim} className="tab">
                    <div className="hide">
                        <motion.li variants={TitleAnim} >Branding</motion.li>
                    </div>
                    <div className="hide">
                        <motion.li variants={TitleAnim} >Design</motion.li>
                    </div>
                    <div className="hide">
                        <motion.li variants={TitleAnim} >Content Creation</motion.li>
                    </div>
                    <div className="hide">
                        <motion.li variants={TitleAnim} >Brand personality</motion.li>
                    </div>
                    <div className="hide">
                        <motion.li variants={TitleAnim} >Teaser</motion.li>
                    </div>
                    <div className="hide">
                        <motion.li variants={TitleAnim} >social Media</motion.li>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    )
}

export default Services;