import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'

// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// month one
import cvm1 from "../../assets/projects/coffea/COFFEA/M1/P16.mp4"
import cvm2 from "../../assets/projects/coffea/COFFEA/M1/P18.mp4"
import cvm3 from "../../assets/projects/coffea/COFFEA/M1/P2.mp4"
import cvm4 from "../../assets/projects/coffea/COFFEA/M1/P21.mp4"
import cim1 from "../../assets/projects/coffea/COFFEA/M1/P12.png"
import cim2 from "../../assets/projects/coffea/COFFEA/M1/P19.png"
import cim3 from "../../assets/projects/coffea/COFFEA/M1/P24.png"
import cim4 from "../../assets/projects/coffea/COFFEA/M1/P26.png"
import cim5 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/1.png"
import cim6 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/2.png"
import cim7 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/3.png"
import cim8 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/4.png"
import cim9 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/5.png"
import cim10 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/6.png"
import cim11 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/7.png"
import cim12 from "../../assets/projects/coffea/COFFEA/M1/new/Revised Final Artwork/8.png"

// month 2
import m2v1 from "../../assets/projects/coffea/COFFEA/M2/P8.mp4"
import m2v2 from "../../assets/projects/coffea/COFFEA/M2/P6.mp4"
import m2v3 from "../../assets/projects/coffea/COFFEA/M2/P4.mp4"
import m2v4 from "../../assets/projects/coffea/COFFEA/M2/P14.mp4"
import m2i1 from "../../assets/projects/coffea/COFFEA/M2/P11-1.png"
import m2i2 from "../../assets/projects/coffea/COFFEA/M2/P11-2.png"
import m2i3 from "../../assets/projects/coffea/COFFEA/M2/P13.png"
import m2i4 from "../../assets/projects/coffea/COFFEA/M2/P25.png"



export const Coffea = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage coffeaImgBef">
                            <div className="overlay"></div>
                            <h1>COFFEA</h1>
                        </div>
                    </div>
                    <h1>Coffea Story</h1>
                    <div className="intro">
                        <p>The coffee experience for modern times. We want to do more than high quality coffee. We want to create unique experiences through
                            positive associations with our brand — that elevate us to more than just another coffee shop. A warm feeling knowing we care about
                            our customer’s well-being.</p>
                        <p>WE BELIEVE IN SIMPLICITY, QUALITY + VALUE. We believe that people are more than their productivity. We believe that it’s ok to
                            take a break. To take a moment for yourself, pause and refresh during that afternoon lull.</p>
                        <p>Life is stressful. We’re hoping you’ll let us help you create a moment of calm focus in your day. And what’s better than taking some time
                            for yourself to pause and refresh? That cozy feeling knowing your afternoon trip to Coffea is happening soon. And there’s a nice bit of
                            caffeine at the end of the tunnel.</p>
                        <p>Create a moment in your day to pause and reflect on the things that matter — grab a cup, then proceed with a lifted focus.</p>
                    </div>
                    <h1>First Month</h1>
                    <div className="imge vid" style={{ paddingTop: "0px" }} >
                        <div className="f">
                            <video src={cvm1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={cvm2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={cvm3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={cvm4 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" src={cim1} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim7} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim11} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim4} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim6} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim9} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim10} alt="" />
                            </div>

                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim12} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim2} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim3} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim5} alt="" />
                            </div>
                            <div className="f amb ambre">
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={cim8} alt="" />
                            </div>

                        </div>
                    </div>
                    <h1>Second Month</h1>
                    <div className="imge vid" style={{ paddingTop: "0px" }} >
                        <div className="f">
                            <video src={m2v1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={m2v2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={m2v3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={m2v4 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={m2i1} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={m2i2} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={m2i3} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={m2i4} alt="" />
                            </div>
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}
