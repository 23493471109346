import React from 'react'
import { motion } from 'framer-motion'
import { PageAnimation, TitleAnim } from '../Animation'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import icon from "../assets/projects/icons8-menu.svg"
import close from "../assets/projects/icons8-close.svg"


const Navbar = () => {
    const [isOpen, setIsopen] = useState(false);
    return (

        <>

            <motion.nav variants={PageAnimation} initial="hidden" animate="show" exit="exit" className='desktop' >
                <div className="left_nav">
                    <motion.li className='hide' variants={TitleAnim} > <HashLink to="#service">  Service  </HashLink></motion.li>
                    <motion.li className='back hide' variants={TitleAnim}> <HashLink to="#about" > About Us  </HashLink></motion.li>
                </div>
                <div className="right_nav">
                    <motion.li className='hide' variants={TitleAnim} > <HashLink to="#client"> Our Work  </HashLink></motion.li>
                    <motion.li className='hide' variants={TitleAnim}> <HashLink to="#contact" > Contact Us  </HashLink></motion.li>
                </div>
            </motion.nav>
            <nav className='innerHome phnNav'>
                <div className="centerNav">
                    <Link to='/'> COMMONPLACE </Link>
                </div>
                <div className="navMenu">
                    <div className="button">
                        {isOpen ? <img onClick={() => { setIsopen(!isOpen) }} src={close} alt="" /> : <img onClick={() => { setIsopen(!isOpen) }} src={icon} alt="" />}

                    </div>
                    {isOpen && <div onClick={() => { setIsopen(!isOpen) }} className="menuItem">
                        <HashLink to="/#about" > About Us  </HashLink>
                        <HashLink to="/#client"> Our Work  </HashLink>
                        <HashLink to="/#service">  Services  </HashLink>
                        <HashLink to="/#contact" > Contact Us  </HashLink>
                    </div>}
                </div>
            </nav>
        </>
    )
}

export default Navbar