import React from 'react'
import About from "../About";
import Clients from "../Clients";
import Contact from "../Contact";
import Footer from "../Footer";
import HomeCam from "../HomeCam";
import HomeTitle from "../HomeTitle";
import Navbar from "../Navbar";
import Services from "../Services"
import { motion } from 'framer-motion'
import { PageAnimation } from '../../Animation'
import ScrollTop from '../ScrollTop';

const Home = () => {



    return (
        <motion.div variants={PageAnimation} initial="hidden" animate="show" exit="exit">
            <Navbar />
            <HomeTitle />
            <HomeCam />
            <About />
            <Clients />
            <Services />
            <Contact />
            <Footer />
            <ScrollTop />
        </motion.div>
    )
}

export default Home