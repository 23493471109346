import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import coverF from "../../assets/projects/figo/logo.jpg";
import coverR from "../../assets/projects/ray/paper/3.jpg";
import coverS from "../../assets/projects/shukran/IMG_7587.JPG";
import coverSoil from "../../assets/projects/soil/02-min.jpeg";
import { client } from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

const initialProjects = [
  { to: "/figo", name: "Figo", src: coverF },
  { to: "/ray", name: "Ray", src: coverR },
  {
    to: "/shukran",
    name: "Shukran",
    src: coverS,
  },
  { to: "/soil", name: "Soil", src: coverSoil },
];

const Recent = () => {
  const [projects, setProjects] = useState();

  useEffect(() => {
    (async () => {
      try {
        const data = await client.fetch(
          `
                *[_type == "project"]  {
                  _id,
                  name,
                  thumbnailImage,
                }
              `
        );
        setProjects(data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  function urlFor(source) {
    return ImageUrlBuilder(client).image(source);
  }

  const getRecentProjects = (projects) => {
    const leftProjectsSize = initialProjects.length - projects.length;
    const res = initialProjects.slice(0, leftProjectsSize);
    const derivedProjects = projects.map(({ _id, name, thumbnailImage }) => ({
      to: `/projects/${_id}`,
      name,
      src: urlFor(thumbnailImage).url(),
    }));
    return [...derivedProjects, ...res];
  };

  if (projects) {
    return (
      <div className="imge">
        {getRecentProjects(projects).map((project, index) => (
          <Link key={index} to={project.to} target="_blank">
            <div className="recproject">
              <h1>{project.name}</h1>
              <LazyLoadImage
                effect="blur"
                height="100%"
                width="100%"
                src={project.src}
                alt=""
              />
            </div>
          </Link>
        ))}
      </div>
    );
  }

  return (
    <div className="imge">
      {initialProjects.map((project, index) => (
        <Link key={index} to={project.to} target="_blank">
          <div className="recproject">
            <h1>{project.name}</h1>
            <LazyLoadImage
              effect="blur"
              height="100%"
              width="100%"
              src={project.src}
              alt=""
            />
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Recent;
