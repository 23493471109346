import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useState } from 'react'
import icon from "../../assets/projects/icons8-menu.svg"
import close from "../../assets/projects/icons8-close.svg"

const Nav = () => {
    const [isOpen, setIsopen] = useState(false);
    return (
        <>
            <nav className='innerHome'>
                <div className="centerNav">
                    <Link to='/'> COMMONPLACE </Link>
                </div>
                <div className="navMenu">
                    <div className="button">
                        {isOpen ? <img onClick={() => { setIsopen(!isOpen) }} src={close} alt="" /> : <img onClick={() => { setIsopen(!isOpen) }} src={icon} alt="" />}

                    </div>
                    {isOpen && <div onClick={() => { setIsopen(!isOpen) }} className="menuItem">
                        <HashLink to="/#about" > About Us  </HashLink>
                        <HashLink to="/#client"> Our Work  </HashLink>
                        <HashLink to="/#service">  Services  </HashLink>
                        <HashLink to="/#contact" > Contact Us  </HashLink>
                    </div>}
                </div>
            </nav>
        </>
    )
}

export default Nav