import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// month one
import mov1 from "../../assets/projects/trb/month1/video/1.mp4"
import mov2 from "../../assets/projects/trb/month1/video/2.mp4"
import mov3 from "../../assets/projects/trb/month1/video/3.mp4"
import mov4 from "../../assets/projects/trb/month1/video/4.mp4"
import moi2 from "../../assets/projects/trb/month1/image/2.jpg"
import moi3 from "../../assets/projects/trb/month1/image/3.jpg"
import moi4 from "../../assets/projects/trb/month1/image/4.jpg"

// month two
import mtv1 from "../../assets/projects/trb/month2/v1.mp4"
import mti1 from "../../assets/projects/trb/month2/1.jpg"
import mti3 from "../../assets/projects/trb/month2/3.jpg"
import mti4 from "../../assets/projects/trb/month2/4.jpg"
import mti5 from "../../assets/projects/trb/month2/5.jpg"
import mti6 from "../../assets/projects/trb/month2/6.jpg"

// month three
import mttv1 from "../../assets/projects/trb/month3/v1.mp4"
import mtiv1 from "../../assets/projects/trb/month3/1.jpg"
import mtiv2 from "../../assets/projects/trb/month3/3.png"
import mtiv3 from "../../assets/projects/trb/month3/4.jpg"
import mtiv4 from "../../assets/projects/trb/month3/5.jpg"
import mtiv6 from "../../assets/projects/trb/month3/7.jpg"



export const Trbbranding = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage trbImgBef">
                            <div className="overlay"></div>
                            <h1>The Royal Burger Social Media</h1>
                        </div>
                    </div>
                    <h1>Brand Story</h1>
                    <div className="intro">
                        <p>In a mysterious turn of events, four individuals received anonymous envelopes containing burger packaging. As they opened
                            the envelopes, a distant bell rang, leaving them perplexed as to its origin. Their curiosity deepened, but answers remained
                            elusive.</p>
                        <p>Their paths crossed at an artist's concert where they encountered a farmer carrying the same burger packaging. A collective
                            realization swept over them, connecting the dots of this enigmatic puzzle. In a ﬁnal twist, they shared a symbolic meal at a
                            drive-thru shop, uniting as a newfound family. The design of their social media presence left the audience pondering their true
                            belonging, intrigued by the lingering question that bound them together.</p>

                    </div>
                    <h1>Social Media First Month </h1>
                    <div className="imge vid" style={{ paddingTop: 0 }} >
                        <div className="f trb">
                            <video src={mov1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f trb">
                            <video src={mov2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f trb">
                            <video src={mov3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f trb">
                            <video src={mov4 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>
                    <div className="imge vid">
                        <div className="imge fleImg">
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={moi2} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={moi3} alt="" />
                            </div>
                            <div className="f amb fle">
                                <LazyLoadImage effect="blur" src={moi4} alt="" />
                            </div>
                        </div>
                    </div>
                    <h1>Social Media Second Month</h1>
                    <div style={{ paddingTop: 0, justifyContent: 'flex-start' }} className="logos">
                        <div className="imageLogo ambImgLogo rellamb" style={{ width: "22vw !important" }} >
                            <video src={mtv1 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img effect="blur" src={mti1} alt="" />
                            <img effect="blur" src={mti3} alt="" />
                            <img effect="blur" src={mti4} alt="" />
                            <img effect="blur" src={mti5} alt="" />
                            <img effect="blur" src={mti6} alt="" />
                        </div>
                    </div>
                    <h1>Social Media Third Month</h1>
                    <div style={{ paddingTop: 0, justifyContent: 'flex-start' }} className="logos">
                        <div className="imageLogo ambImgLogo rellamb" style={{ width: "22vw !important" }} >
                            <video src={mttv1 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="logoVid ambLogoVid">
                            <img effect="blur" src={mtiv1} alt="" />
                            <img effect="blur" src={mtiv2} alt="" />
                            <img effect="blur" src={mtiv3} alt="" />
                            <img effect="blur" src={mtiv4} alt="" />
                            <img effect="blur" src={mtiv6} alt="" />
                        </div>

                    </div>


                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}
