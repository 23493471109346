import React from 'react'

const Footer = () => {
    return (
        <footer className='container footer'>
            <div className="componentTitle ">
                <div className="timeLine">7 / 7</div>
            </div>
            <div className="footer_content">
                <p>
                    <span>&copy;2022 All Rights Reserved </span>
                    {/* develop by Yash Ghori */}
                </p>
            </div>
        </footer>
    )
}

export default Footer