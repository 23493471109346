import { useParams } from "react-router-dom";
import { client } from "../../client";
import { useEffect, useState } from "react";
import "../../styles/_projectslug.scss";
import imageUrlBuilder from "@sanity/image-url";
import Nav from "./Nav";
import ImagePreview from "../ImagePreview";
import Recent from "./Recent";
import Contact from "../Contact";

const ProjectSlug = () => {
  const { id } = useParams();
  const [project, setProject] = useState();
  const [toggleImagePreview, setToggleImagePreview] = useState(false);
  const [previewAsset, setPreviewAsset] = useState({ imgSrc: null, alt: null });

  function urlFor(source) {
    return imageUrlBuilder(client).image(source);
  }

  useEffect(() => {
    (async (id) => {
      try {
        const data = await client.fetch(
          `
            *[_type == "project"]{
              _id,
              name,
              thumbnailImage,
              sections[]{
                sectionName,
                images[],
                videoshow[]{
                  _type,
                  asset-> {
                    url,
                    mimeType
                  }
                },
                sectionDescription
              }
            }
          `
        );

        setProject(data.find(({ _id }) => _id === id));
      } catch (err) {
        console.error(err);
      }
    })(id);
  }, [id]);

  const { imgSrc, alt } = previewAsset;

  if (project) {
    const {
      _type,
      name,
      description,
      thumbnailImage,
      sections,
      _createdAt,
      _rev,
      _id,
      _updatedAt,
      slug,
    } = project;

    const containerStyle = {
      "--dynamic-background": `url(${urlFor(project?.thumbnailImage).url()})`,
    };

    return (
      <>
        {/* Image preview on click of image */}
        <div style={{ position: "relative" }}>
          {toggleImagePreview ? (
            <ImagePreview
              asset={{ imgSrc, alt }}
              togglePreview={setToggleImagePreview}
            />
          ) : null}
        </div>
        <Nav />
        {/* <div className="overlay-container">
          <img
            src={urlFor(project?.thumbnailImage).url()}
            alt="overlay-bg"
            className="background-image"
          />
          <div className="overlay-text">{name}</div>
        </div> */}

        <div className="figo">
          <div className="container">
            <div className="backImage" style={containerStyle}>
              <div className="overlay"></div>
              <h1>{name}</h1>
            </div>
          </div>
          {sections.length
            ? sections.map((section, index) => (
                <div className="section-container" key={index}>
                  <section className="project-section">
                    <p className="section-heading">{section.sectionName}</p>
                    <p className="section-description">
                      {section?.sectionDescription}
                    </p>
                    <div className="images-container">
                      {section.images?.length
                        ? section.images.map((img, i) => (
                            <div
                              className={
                                section.images.length === 1
                                  ? "image-container-for-one"
                                  : section.images.length === 2
                                  ? "image-container-for-two"
                                  : "image-container"
                              }
                              key={i}
                            >
                              <img
                                src={urlFor(img).url()}
                                alt="overlay-bg"
                                className="image-asset"
                                onClick={() => {
                                  setToggleImagePreview((prev) => !prev);
                                  setPreviewAsset({
                                    imgSrc: urlFor(img).url(),
                                    alt: urlFor(img).url(),
                                  });
                                }}
                              />
                            </div>
                          ))
                        : null}
                    </div>
                    {section.videoshow && section.images ? (
                      <h2>Other Visual Assets</h2>
                    ) : null}
                    {section.videoshow ? (
                      <div className="images-container">
                        {section.videoshow.map((vid, j) => (
                          <div
                            className={
                              section.videoshow.length === 1
                                ? "video-container-for-one"
                                : section.videoshow.length === 2
                                ? "image-container-for-two"
                                : "image-container"
                            }
                            key={j}
                          >
                            <video
                              controls
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "3px",
                              }}
                            >
                              <source
                                src={vid.asset.url}
                                type={vid.asset.mimeType}
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </section>
                </div>
              ))
            : null}

          <h1 style={{ marginTop: "6rem" }}>Recent Projects</h1>
          <Recent />
          <Contact />
        </div>
      </>
    );
  }
  return null;
};

export default ProjectSlug;
