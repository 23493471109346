import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import ScrollTop from '../ScrollTop'
import Contact from '../Contact'
import Recent from './Recent'
import Loader from './Loader'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// png
import p1 from "../../assets/projects/take/png/1.png"
import p2 from "../../assets/projects/take/png/2.png"
import p3 from "../../assets/projects/take/png/3.png"
import p4 from "../../assets/projects/take/png/4.png"
// photos
import f1 from "../../assets/projects/take/photos/1.png"
import f2 from "../../assets/projects/take/photos/2.png"
import f3 from "../../assets/projects/take/photos/3.png"
import f4 from "../../assets/projects/take/photos/4.png"
import f6 from "../../assets/projects/take/photos/6.png"
import f5 from "../../assets/projects/take/photos/5.png"
import f7 from "../../assets/projects/take/photos/7.png"
import f8 from "../../assets/projects/take/photos/8.png"
import f9 from "../../assets/projects/take/photos/9.png"
import f10 from "../../assets/projects/take/photos/10.png"
import f11 from "../../assets/projects/take/photos/11.png"
import f12 from "../../assets/projects/take/photos/12.png"
import f13 from "../../assets/projects/take/photos/13.png"
import f14 from "../../assets/projects/take/photos/14.png"
import f15 from "../../assets/projects/take/photos/15.png"
import f16 from "../../assets/projects/take/photos/16.png"
import f17 from "../../assets/projects/take/photos/17.png"
import f18 from "../../assets/projects/take/photos/18.png"
import f19 from "../../assets/projects/take/photos/19.png"
import f20 from "../../assets/projects/take/photos/20.png"
import f21 from "../../assets/projects/take/photos/21.png"
import f22 from "../../assets/projects/take/photos/22.png"
import f23 from "../../assets/projects/take/photos/23.png"
import f24 from "../../assets/projects/take/photos/24.png"
import f25 from "../../assets/projects/take/photos/25.png"
import f26 from "../../assets/projects/take/photos/26.png"

const Take = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <> <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage takeImgBef">
                            <div className="overlay"></div>
                            <h1>TAKE BRANDING</h1>
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={p1} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={p2} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={p3} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={p4} alt="" />
                        </div>
                    </div>
                    <div className="imge">
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f1} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f2} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f3} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f4} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f5} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f6} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f7} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f8} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f9} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f10} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f11} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f12} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f13} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f14} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f15} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f16} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f17} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f18} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f19} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f20} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f21} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f22} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f23} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f24} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f25} alt="" />
                        </div>
                        <div className="f amb inter">
                            <LazyLoadImage effect="blur" src={f26} alt="" />
                        </div>
                    </div>
                    <h1>Recent Projects</h1>
                    <Recent />
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )
}

export default Take