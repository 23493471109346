import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import ScrollTop from "../ScrollTop";
import Contact from "../Contact";
import Recent from "./Recent";
import Loader from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import l1 from "../../assets/projects/omyaabranding/2B.jpg";
import l2 from "../../assets/projects/omyaabranding/3A.jpg";
import l3 from "../../assets/projects/omyaabranding/4A.jpg";
import l4 from "../../assets/projects/omyaabranding/5B.jpg";
import l5 from "../../assets/projects/omyaabranding/6B.jpeg";
import l6 from "../../assets/projects/omyaabranding/7B.jpg";
import l7 from "../../assets/projects/omyaabranding/8B.jpg";
import l8 from "../../assets/projects/omyaabranding/omyo-poster-mockup.jpg";
import l9 from "../../assets/projects/omyaabranding/omyo-poster.jpg";

export const Oomiya = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Nav />
          <div className="figo">
            <div className="container">
              <div className="backImage oomiyaImgBef">
                <div className="overlay"></div>
                <h1>Oomiya Branding</h1>
              </div>
            </div>
            <h1>Story</h1>
            <div className="para">
              <p>
                The 70’s/80’s saw a transformation in Saudi society, embracing
                Western attire & hairstyles became emblematic of this societal
                shift towards modernity—a canvas painted by factors like
                international travel and the influence of education. OMYO is
                born from this captivating crossroads of time and culture,
                embodies the spirit of an era that resonates with both nostalgia
                and innovation.
              </p>
            </div>
            <h1>Branding</h1>
            <div className="imge">
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l1}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l2}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l3}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l4}
                  alt=""
                />
              </div>
            </div>
            <div className="imge">
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l5}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l6}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l7}
                  alt=""
                />
              </div>
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l8}
                  alt=""
                />
              </div>
            </div>
            <div className="imge">
              <div className="f amb ">
                <LazyLoadImage
                  effect="blur"
                  height="100%"
                  width="100%"
                  src={l9}
                  alt=""
                />
              </div>
            </div>
            <h1>Recent Projects</h1>
            <Recent />
          </div>
          <Contact />
          <ScrollTop />
        </>
      )}
    </>
  );
};
