import React, { useEffect, useState } from 'react'
import f1 from "../../assets/projects/so1.jpg"
import f2 from "../../assets/projects/soil.jpg"
import ScrollTop from '../ScrollTop'
import v1 from "../../assets/projects/soil/v1.mp4"
import v2 from "../../assets/projects/soil/v2.mp4"
import v3 from "../../assets/projects/soil/v3.mp4"
import v4 from "../../assets/projects/soil/v4.mp4"
import ig1 from "../../assets/projects/soil/02-min.jpeg"
import ig2 from "../../assets/projects/soil/POST 5-min.png"
import { Link } from 'react-router-dom'
import coverF from "../../assets/projects/figo/logo.jpg"
import coverA from "../../assets/projects/amb/brand/9.jpg"
import coverR from "../../assets/projects/ray/paper/3.jpg"
import coverS from "../../assets/projects/shukran/IMG_7587.JPG"
import Nav from './Nav'
import Contact from '../Contact'
// lazt loading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from './Loader'

const Soil = () => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? <Loader /> : <>
                <Nav />
                <div className='figo'>
                    <div className="container">
                        <div className="backImage soilImgBef">
                            <div className="overlay"></div>
                            <h1>SOIL</h1>
                        </div>
                    </div>
                    <h1>Soil Story</h1>
                    <div className="intro">
                        <p>
                            Have you ever wondered about the adventures of the coffee beans before they ended on your cup?
                            Soil is the land of dreams, a land rich in flavor and full of surprises. You'll enjoy the journey of your coffee in every sip. Experience it, only at SoIL.
                        </p>
                    </div>
                    <div className="imge vid">
                        <div className="f">
                            <video src={v1 + "#t=1"} controls="controls" muted alt=""></video>
                        </div>
                        <div className="f">
                            <video src={v2 + "#t=1"} controls="controls" muted alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={v3 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                        <div className="f">
                            <video src={v4 + "#t=1"} controls="controls" alt="" ></video>
                        </div>
                    </div>

                    <div className="imge vid">
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f1} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={f2} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={ig1} alt="" />
                        </div>
                        <div className="f">
                            <LazyLoadImage effect="blur" height="100%" width="100%" src={ig2} alt="" />
                        </div>
                    </div>
                    <div className="services">
                        <h1>SERVICES:</h1>
                        <ul>
                            <li>Art direction</li>
                            <li>Social Media</li>
                        </ul>
                    </div>


                    <h1>Recent Projects</h1>
                    <div className="imge">
                        <Link to="/figo">
                            <div className=" recproject">
                                <h1>Figo</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverF} alt="" />
                            </div>
                        </Link>
                        <Link to="/ray">
                            <div className=" recproject">
                                <h1>Ray</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverR} alt="" />
                            </div>
                        </Link>
                        <Link to="/ambiance">
                            <div className=" recproject">
                                <h1>Ambiance</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverA} alt="" />
                            </div>
                        </Link>
                        <Link to="/shukran">
                            <div className=" recproject">
                                <h1>Shukran</h1>
                                <LazyLoadImage effect="blur" height="100%" width="100%" src={coverS} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
                <Contact />
                <ScrollTop /></>}
        </>
    )

}

export default Soil